import React, { forwardRef, useRef, useMemo, useImperativeHandle,useEffect } from "react";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.min.css";
import "./resetSurvey.scss"


const SurveyForm = forwardRef((props, ref) => {
    const { setSurveyData, setSurveyValidation } = props;

    const surveyJson = useMemo(() => ({
        showNavigationButtons: false,
        name: "demographics",
        elements: [
            {
                type: "radiogroup",
                name: "gender",
                title: "How do you identify?",
                choices: [
                    { text: "Male", value: "male", category: "By Birth" },
                    { text: "Female", value: "female", category: "By Birth" },
                    { text: "Non-Binary", value: "nonbinary", category: "Other Identities" },
                    { text: "Prefer not to say", value: "prefer_not_say", category: "Other Identities" }
                ],
                hasOther: true,  // Enables "Other" option
                otherText: "Other (please specify)", // Custom label for "Other"
                isRequired: true
       
            },
            {
                type: "dropdown",
                name: "education",
                title: "What is the highest level of education you have completed?",
                choices: [
                    { value: "high-school", text: "High School" },
                    { value: "associate-degree", text: "Associate Degree" },
                    { value: "bachelor", text: "Bachelor’s Degree" },
                    { value: "master", text: "Master’s Degree" },
                    { value: "phd", text: "PhD" },
                    
                ], 
                  hasOther: true,  // Enables "Other" option
                otherText: "Other (please specify)", // Custom label for "Other"
                isRequired: true
            },
        
            {
                type: "dropdown",
                name: "employment",
                title: "What is your current employment status?",
                description: "Descibe how you feeling in your job",
                titleTooltip: "Get help here",
                choices: [
                    { value: "full-time", text: "Employed full-time" },
                    { value: "part-time", text: "Employed part-time" },
                    { value: "self-employed", text: "Self-employed" },
                    { value: "unemployed", text: "Unemployed" },
                    { value: "student", text: "Student" },
                    { value: "retired", text: "Retired" }
                ],
                isRequired: false
            },
            {
               type: "checkbox",
              name: "favoriteFruits",
              title: "Select your favorite fruits:",
              choices: [
                    "Apple",
                    "Banana",
                    "Orange",
                    "Grapes",
                    "Mango"
                  ],
                hasOther: true,  // Enables "Other" option
                otherText: "Other (please specify)", // Custom label for "Other"
                isRequired: true
            },
            {
                type: "text",
                name: "age",
                title: "Enter your age:",
                inputType: "number", // Makes it a numeric input
                isRequired: true,
                min: 1, // Minimum value allowed
                max: 120, // Maximum value allowed
                placeHolder: "Enter a number"
            },
            {
                type: "text",
                name: "company",
                title: "Company:", 
                isRequired: true, 
                placeHolder: "Enter a company"
            },
            {
                "type": "dropdown",
                "name": "confirm",
                "title": "Do you agree?",
                "choices": [
                    { "value": "yes", "text": "Yes" },
                    { "value": "no", "text": "No" }
                ],
                "defaultValue": "yes"
            },
            {
                "type": "checkbox",
                "name": "confirm-dpa",
                "title": "DPA?",
                "choices": [
                    { "value": "yes", "text": "This is a dpa" },
              
                ],
                "defaultValue": ["yes"],
                isRequired: true, 
            },
            {
                "type": "radiogroup",
                "name": "confirm-answer",
                "title": "Do you agree?",
                "choices": [
                    { value: "yes",  text : "yes" },
                    { value: "no", text : "no" },

                ],
                "defaultValue": ["yes"],
            },
            {
                type: "comment",
                name: "suggestions",
                title: "Text Area 5 rows",
                isRequired: true,
                placeHolder: "Write here...", 
                rows: 5 // Sets the initial height of the textarea
            },
            {
                type: "comment",
                name: "suggestions2",
                title: "Text Area 5 rows with Max length",
                isRequired: true,
                placeHolder: "Write here...",
                maxLength: 500, // Limits input to 500 characters
                rows: 5 // Sets the initial height of the textarea
            },
            {
                "type": "text",
                "name": "phoneNumber",
                "title": "Enter your phone number",
                "isRequired": true,
                "inputType": "tel",
                "validators": [{
                    "type": "regex",
                    "text": "Only numbers, '+', '(', and ')' are allowed.",
                    "regex": "^[0-9+()\\- ]+$"
                }]
            }

        ],
        showCompletedPage: false,
        showQuestionNumbers: "off",
        completedHtml: "",
        questionErrorLocation: "bottom",
        isPanelless: true,
    }), []);

    const surveyModel = useRef(new Model(surveyJson)); // SurveyJS model initialization
 

    useEffect(() => {
        console.log("my", surveyModel.current);
        const model = surveyModel.current;
        console.log("Model", model);
       
        if (model) {
     
        }

        return () => {
            if (model) {
                model.onAfterRenderQuestion.remove(); // Cleanup event listener
            }
        };
    }, []);

    useImperativeHandle(ref, () => ({
        validateSurvey: () => surveyModel.current.validate(),
        getSurveyData: () => surveyModel.current.data
    }));

    return <Survey model={surveyModel.current} />;
});

export default SurveyForm;

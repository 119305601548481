import React, { useRef, useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom'
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import '../../i18n';
import "../../general.scss";
import { CreateMinimalBasketFromTxActivity, GetBasket } from './Payment/Basket';
import MessageBox from '../UI/MessageBox/MessageBox';
import ReactHtmlParser from 'html-react-parser';
import { t } from 'i18next';
import { Loading } from '../Utilities/Loading';
import { useAuth } from '../Authentication/AuthenticationProvider';

export function OrderUpgrade(props) {
    const {brandingElement} = props;
    const searchParams = new URLSearchParams(window.location.search);   
    const { txactivityid } = useParams();
    const { txactivityhash } = useParams();
    const [errorStates, setErrorStates] = useState({"upgradeUnavailable":false});
    const [isLoading, setIsLoading] = useState(true);
    const auth = useAuth();

    useEffect(() => {
        
        sessionStorage.clear(); //cleaning prev flow's data in case any
        CreateMinimalBasketFromTxActivity({ txActivityId: txactivityid, txActivityHash: txactivityhash }).then((result) => { 
        loadBasket(result.basketid, "self") 
          
        }).catch((err) => {
            setIsLoading(false)
            setErrorStates({"upgradeUnavailable": true});
         });

    }, [])

    const loadBasket = (fid, purchaseTarget) => {

        GetBasket({ "fid": fid, "loadfromserver": true, "userToken": auth?.userData?.token, "purchaseTarget": purchaseTarget }).then((result) => {
        redirectToCheckout(result?.id);

        }).catch((err) => {
            setIsLoading(false)
            console.log("Issue with loading basket")
        });
    }

    const redirectToCheckout = (id) => {

        let redirectUrlString = "";
        const existingParams = searchParams.toString() && searchParams || "";
        const newParam = `purchasingFor=self`;

        redirectUrlString = '/payment/' + id;

        if (existingParams) {
            redirectUrlString += "?" + existingParams + "&" + newParam;
        } else {
            redirectUrlString += "?" + newParam;
        }
       
        window.location.href = redirectUrlString;

    }

    return (
        <>
         
           {errorStates?.upgradeUnavailable ?  <MessageBox messageState="error">{ReactHtmlParser(brandingElement?.upgradeErrorText||"")}</MessageBox>
           : <Loading loading={isLoading} />}
      
        </>
    );
}

import React, { forwardRef,useContext, useState, useImperativeHandle, useRef, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { loadStripe } from '@stripe/stripe-js';
import { APIGet, APIPost } from '../../API/APIRequest';
import { useElements, useStripe, Elements, PaymentElement } from '@stripe/react-stripe-js';
import '../../../i18n'
import { PaymentFormContext, updateBillingAddress } from '../PaymentFormReducer';
import InputWrapperRefacture from '../../UI/Inputs/InputWrapperRefacture';
import ErrorBox from '../../UI/ErrorBox/ErrorBox';
import { gtmEventTriggers } from '../../Utilities/GtmFunctions';
import MessageBox from '../../UI/MessageBox/MessageBox';


export const InitialiseStripe = (props) => {
    return new Promise((resolve, reject) => {
        let basket = props.basket;
        const data = new FormData();
        let action = "createpaymentintent";
        if (basket.offertypename === "paid free trial" || basket.offertypename === "free trial")
        {
            action = "createsetupintent";
        }
        data.append("basketId", basket.id);
        data.append("paymentTypeId", props.paymentSelection);
        data.append("publisherCurrencyId", basket.publishercurrencyid);

        APIPost({
            "controller": "stripe",
            "action": action,
            "environment": null,
            "data": data
        })
            .then((response) => {
                let publicKey = response.data.paymentproviderusername;
                
                let stripeResult = {
                    "publickey": publicKey,
                    "secret": response.data.secretkey,
                    "intentid": response.data.stripeintentid
                };
             
              resolve(stripeResult);
            }).catch((error) => {
                
                reject(error);
            });
        });

}

export const UpdateStripePaymentRequest = (props) => {
    return new Promise((resolve, reject) => {
        let basket = props.basket;
        console.log(basket);
        let action = "updatepaymentintent";
        if (basket.offertypename === "paid free trial") {
            action = "updatesetupintent";
        }
        const data = new FormData();
        data.append("basketId", basket.id);
        data.append("orderId", props.orderId);
        data.append("stripeIntentId", props.stripeIntentId);
        APIPost({
            "controller": "stripe",
            "action": action,
            "environment": null,
            "data": data
        })
            .then((response) => {
                resolve(response);
            }).catch((error) => {
                reject(error);
            });
    });
}

export const GetCardInfo = (props) => {
    return new Promise((resolve, reject) => {
        APIGet({
            "controller": "stripe",
            "action": "getcardinfo",
            "environment": null,
            "identifier": props.fid
        }).then((cardResponse) => {
            resolve(cardResponse.data);
        }).catch((cardError) => {
            var unexpected = [];
            unexpected.push("error.unexpected");
            reject(unexpected);
        });
    });
}

export const StripeForm = forwardRef((props, ref) => {
    let options = {
        paymentMethodCreation: 'manual',
       clientSecret: props.stripeCredentials.secret,
        appearance: {
            theme: 'stripe',
            variables: {
                colorPrimary: '#0570de',
                colorBackground: '#fafafa',
                colorText: '#30313d',
                colorDanger: '#df1b41', 
                borderRadius: '0px',
                fontSizeSm: '16px',
                colorText: "#00000",
                spacingGridRow: '24px',
                colorDanger: '#DD2B1B'
                // See all possible variables below
            },
            rules: {
                '.p-GridCell': {
                    marginBottom: '24px'
                },
                '.Label': {
                    paddingBottom: '8px',
                    fontWeight: '700',
                    fontSize:'14px'

                },
                '.Input': {
                    border: '1px solid transparent',
                    boxShadow: '0px 0px 0px 1px #1175D2',
                },
                '.Input--empty': {
                    border: '1px solid transparent',
                    boxShadow: '0px 0px 0px 1px #DCDCDC',
                },
                '.Input--empty:focus': {
                    border: '1px solid transparent',
                    boxShadow: '0px 0px 0px 2px #1175D2',
                },
                '.Input:focus': {
                    border: '1px solid transparent',
                    boxShadow: '0px 0px 0px 1px #1175D2',
                },
                '.p-Input--focused': {
                    border: '1px solid transparent',
                    boxShadow: '0px 0px 0px 1px #1175D2',
                },
                '.Input--invalid': {
                    boxShadow: '0px 0px 0px 1px #DD2B1B',
                    color: '#000',
                    border: '1px solid transparent',
                    backgroundColor: '#FFF4F4'
                }, 
                '.Input--invalid:after': {

                },
                '.Error': {
                    fontSize: '14px',
                    marginTop: '8px',
                    borderLeft: '2px solid #DD2B1B', 
                    paddingLeft:'8px'
                }
            }
        },
        loader: 'always',
        
    } 
    
   const [stripePromise, setStripePromise] = useState(() => loadStripe(props.stripeCredentials.publickey))
  
    
    return (
        <Elements stripe={stripePromise} options={options} >
           <StripeElement setErrorStates={props.setErrorStates} enableWallets={props.enableWallets} setStripeMethodName={props.setStripeMethodName} errorStates={props.errorStates} creditCardHolderName={props.creditCardHolderName} setCreditCardHolderName={props.setCreditCardHolderName} setIsLoading={props.setIsLoading} ref={ref} basket={props.basket} />
        </Elements>
    );
});

const StripeElement = forwardRef((props, ref) => {
    const stripe = useStripe();
    const elements = useElements();
    const [errors, setErrors] = useState([]);
    const { t, i18n } = useTranslation();
    const {setErrorStates, errorStates, creditCardHolderName, setCreditCardHolderName, enableWallets}=props;
    const [cardHolderName, setCardHolderName] = useState();
    const [showCardHolderNameField, setShowCardHolderNameField] = useState();
    const {setStripeMethodName} = props;
    const stripeNotificationCheckDelay = 5000;
    var params = (new URL(document.location)).searchParams;
  
   
    const stripeCardErrorHandler=(error)=>{
     
        if(error.code==="card_declined" ||
           error.code==="expired_card" ||
           error.code==="incorrect_cvc" ||
           error.code==="processing_error" ||
           error.code==="incorrect_number"){

             setErrorStates(errorStates=>({...errorStates, cardDeclined:true }))

            }
    }
    const OnStripeNotification = (status,orderid,stripeid,error) => {
        switch (status) {
            case "S":
                const baseUrl = window.location.protocol + "//" + window.location.host + "/StripeResponseHandler/" + props.basket.id;
                let stripeparamname = stripeid.startsWith('pi_') ? 'payment_intent' : 'setup_intent';
                const notificationParam = "notificationredirect=1&orderid=" + orderid + "&" + stripeparamname + "=" + stripeid;
                let currentParams = window.location.search;
                let return_url_base = `${baseUrl}?${notificationParam}${currentParams ? '&' + currentParams.slice(1) : ''}`;

                window.location.href = return_url_base; // success -> 
                break;
            case "P":
                if (doCheckStripeNotification)
                    setTimeout(CheckStripeNotification, stripeNotificationCheckDelay);
                break;
            case "FF":    // Failed payment handler currently inactive. change check to F to handle failed payments
                //let paymentError = {
                //    code: "paymentFailed"
                //};
                doCheckStripeNotification = false;
                stripeCardErrorHandler(error)
                props.setIsLoading(false);
                break;
            default:
                break;
        }
    }

    let doCheckStripeNotification = true;
    const CheckStripeNotification = () => {
        let fid = props.basket.id;
        APIPost({
            "controller": "stripe",
            "action": "checknotificationreceived",
            "environment": null,
            "identifier": fid
        }).then((response) => {
            OnStripeNotification(response.data.status, response.data.orderid, response.data.stripeid, response.data.error);
        }).catch((error) => {

        });
    }

    const LogAdvanceRecord = (loglevel, message) => {
        let fid = props.basket.id;
        APIPost({
            "controller": "stripe",
            "action": "logadvancerecord",
            "data": {
                "fid": fid,
                "loglevel": loglevel,
                "message": message
            },
            "environment": null,
            "identifier": null
        }).then((response) => {
            
        }).catch((error) => {

        });
    }
    useImperativeHandle(ref, () => ({
        
        createPaymentMethod(purchaser, countryCodes) {
            return new Promise((resolve, reject) => {
                if (!stripe || !elements) {
                    reject('Stripe.js has not loaded yet.');
                    return;
                }

                stripe.createPaymentMethod({
                    elements,
                    params: {
                        billing_details: {
                            name: creditCardHolderName,
                            address: {
                                country: countryCodes?.billingCountryCode ?? "GB",
                                postal_code: purchaser?.address?.POSTCODE ?? "SW1Y 4PH",
                                // country: props.basket.countrycode ?? "GB",   
                                // postal_code: props.basket.postcode ?? "SW1Y 4PH",  
                            }
                        }
                    }
                })
                    .then(({ error, paymentMethod }) => {
                        if (error) {
                            reject(error);
                        } else {
                            resolve(paymentMethod);
                        }
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },        
        confirmPayment(event, isFreeTrial, orderId, purchaser, recipient, countryCodes) {
            
            gtmEventTriggers("add_payment_info");

            let recipientName= recipient?.Firstname + " " + recipient?.Surname;

            let return_url_base = window.location.protocol + "//" + window.location.host + "/StripeResponseHandler/" + props.basket.id + window.location.search;

            doCheckStripeNotification = true;
            setTimeout(CheckStripeNotification, stripeNotificationCheckDelay);

            if (isFreeTrial) {
                stripe.confirmSetup({
                    //`Elements` instance that was used to create the Payment Element
                    elements,
                    confirmParams: {
                        payment_method_data: {
                            billing_details: {
                                name: creditCardHolderName,
                                email: purchaser?.email,
                                address: {
                                    city: purchaser?.address?.TOWN,
                                    country: countryCodes?.billingCountryCode ?? "GB",  //default values
                                    line1: purchaser?.address?.ADDRESS1,
                                    line2: purchaser?.address?.ADDRESS2,
                                    postal_code: purchaser?.address?.POSTCODE ?? "SW1Y 4PH",  
                                    state: purchaser?.address?.COUNTY,
                                   }
                            },
                            metadata: {
                                order_id: orderId,
                            },
                        },
                        return_url: return_url_base,//in case of auto redirect Stripe will add setup_intent=xxxxx
                    },
                }).then((result) => {

                    if (result.error) {
                        doCheckStripeNotification = false;
                        stripeCardErrorHandler(result.error);
                        props.setIsLoading(false);
                    }
                    else if (result.setupIntent && result.setupIntent.status === 'succeeded') {
                        doCheckStripeNotification = false;
                       // Display feedback to user (optional)
                       //console.log("Payment succeeded, redirecting...");

                       // Fallback redirect in case the automatic one fails
                       window.location.href = return_url_base + '&setup_intent=' + result.setupIntent.id;
                   }
                }).catch(e => {
                    doCheckStripeNotification = false;
                    props.setIsLoading(false);
                    LogAdvanceRecord(0, "Exception on stripe.confirmSetup");
                });
            }
            else {
                stripe.confirmPayment({
                    //`Elements` instance that was used to create the Payment Element

                    elements,
                    confirmParams: {
                        payment_method_data: {
                            billing_details: {
                                name: creditCardHolderName,
                                email: purchaser?.email,
                                address: {
                                    city: purchaser?.address?.TOWN,
                                    country: countryCodes?.billingCountryCode ?? "GB",  //default values added
                                    line1: purchaser?.address?.ADDRESS1,
                                    line2: purchaser?.address?.ADDRESS2,
                                    postal_code: purchaser?.address?.POSTCODE ?? "SW1Y 4PH",  
                                    state: purchaser?.address?.COUNTY,
                                   }

                            },
                            metadata: {
                                order_id: orderId,
                            },
                        },
                        shipping: recipientName ? {
                            name: recipientName,
                            address: {
                                city: recipient?.address?.TOWN,
                                country: countryCodes?.deliveryCountryCode,
                                line1: recipient?.address?.ADDRESS1,
                                line2: recipient?.address?.ADDRESS2,
                                postal_code: recipient?.address?.POSTCODE,
                                state: recipient?.address?.COUNTY,
                            },
                        }:{},
                        return_url: return_url_base,//in case of auto redirect Stripe will add payment_intent=xxxxx

                    },
                    redirect: params.get('disableStripeClientRedirect') ? 'if_required' : 'always',

                }).then((result) => {
                   
                    if (result.error) {
                        doCheckStripeNotification = false;
                        stripeCardErrorHandler(result.error);
                        props.setIsLoading(false);
                    }
                    else if (result.paymentIntent && result.paymentIntent.status === 'succeeded' && !params.get('disableStripeClientRedirect')) {
                        doCheckStripeNotification = false;
                        // Display feedback to user (optional)
                        //console.log("Payment succeeded, redirecting...");

                        // Fallback redirect in case the automatic one fails
                        window.location.href = return_url_base + '&payment_intent=' + result.paymentIntent.id;
                    }
                }).catch(e => {
                    doCheckStripeNotification = false;
                    props.setIsLoading(false);
                    LogAdvanceRecord(0, "Exception on stripe.confirmPayment");
                });
            }
        },
        elements:elements,
        errors
    }));

    var optionsForCardAndWallet = {
        fields: {
            billingDetails: {
               
                address: { 
                    country: "never",
                    postalCode: "never"
                },
           
            },
       
        },      
        terms: {
            card: "never"
        },
        
        wallets: {
            applePay : "auto",
            googlePay : "auto",
            paypal:"never",

        },
       
    };

    var optionsForCard = {
        fields: {
            billingDetails: {
               
                address: { 
                    country: "never",
                    postalCode: "never"
                },
           
            },
       
        },      
        terms: {
            card: "never"
        },
       
        wallets: {
            applePay : "never",
            googlePay : "never",
            paypal:"never",

        }
    };


    useEffect(()=>{
    
    //initialising the state for cardHolderBlank error    
    setErrorStates(errorStates => ({ ...errorStates, cardHolderNameBlank: false }));
        
    }, [])
   
    const handleOnBlur = (value) => {
        setCreditCardHolderName(value)  //parent state 
    }

    const handleOnChange = (value) => {

        setCardHolderName(value);
        if (value) {
            setErrorStates(errorStates => ({ ...errorStates, cardHolderNameBlank: false }));   //local state
        }
    }
  

    const handlePaymentMethodChange=(event)=>{

        setStripeMethodName(event.value.type)

        if(event.value.type==="card"){
           setShowCardHolderNameField(true);

        }else{
           setShowCardHolderNameField(false);
        }

    }
  
    return (
        <>

            <div className="item-container">
                <h5 className="module-subheading">{t('creditcardform.title')}</h5>
            </div>

            {errorStates?.cardDeclined &&
                <div className="item-container">
                    <MessageBox messageState="error">{t('creditcardform.carddeclineerror')}</MessageBox>
                </div>}

            <div className="item-container">

                {/* {billingAddress && billingAddress.POSTCODE ? <PaymentElement options={options1} />
                : <PaymentElement options={options2} />} */}
               {enableWallets  ?   <PaymentElement onChange={handlePaymentMethodChange} options={optionsForCardAndWallet} />
               :  <PaymentElement onChange={handlePaymentMethodChange} options={optionsForCard} />}

            </div>

            {showCardHolderNameField && <div className="item-container">
                <div className="item-wrapper">
                    <InputWrapperRefacture
                        label={t('creditcardform.cardholderlabel')}
                        inputSize="full-width"
                        id="cardholder"
                        inputFieldType="text"
                        specs="text_input"
                        name="cardholder"
                        type="text"
                        invalidClass={errorStates.cardHolderNameBlank && "Invalid-input"}
                        aria-invalid={errorStates.cardHolderNameBlank}
                        aria-describedby="cardholder-blank-error"
                        value={cardHolderName}
                        onBlur={(e) => { handleOnBlur(e.target.value) }}
                        onChange={(e) => { handleOnChange(e.target.value) }}
                    />

                    {errorStates.cardHolderNameBlank ? <span role="alert" id='cardholder-blank-error'><ErrorBox icon="false">{t('creditcardform.blankcardholder')}</ErrorBox></span> : ""}

                </div>
            </div>
            } </>
         );

});


export const checkForWalletMethods = async (basket, stripePublicKey, setWalletMethods) => {
    const result = {
      applePayAvailable: false,
      googlePayAvailable: false,
    };
   
    let amount = basket.gross * 100;
    let countryCode= basket.countrycode;
    let currencyCode = basket.currencycode.toLowerCase();
    
    const stripeInstance = window.Stripe(stripePublicKey);
   
     //checking for apple Pay availability
    const applePayRequest = stripeInstance.paymentRequest({
      country: countryCode,
      currency: currencyCode,
      total: {
        label: 'Amount',
        amount: amount,
      },
    });
   
   
    try {
      const applePayResponse = await applePayRequest.canMakePayment();
      if (applePayResponse && applePayResponse.applePay) {
        result.applePayAvailable = true;
        console.log("Apple Pay is available");
      } else {
        result.applePayAvailable = false;
        console.log("Apple Pay is not available");
      }
    } catch (error) {
      console.error("Error checking Apple Pay availability:", error);
    }
  
    //check for Google Pay availability
    const googlePayRequest = stripeInstance.paymentRequest({
      country: countryCode,
      currency: currencyCode,
      total: {
        label: 'Amount',
        amount: amount,
      },
    });
  
    try {
      const googlePayResponse = await googlePayRequest.canMakePayment();
      if (googlePayResponse && googlePayResponse.googlePay) {
        result.googlePayAvailable = true;
        console.log("Google Pay is available");
      } else {
        result.googlePayAvailable = false;
        console.log("Google Pay is not available");
      }
    } catch (error) {
      console.error("Error checking Google Pay availability:", error);
    }
  
    // Update the state with the result, storing for later retention
    sessionStorage.setItem("AvailableWalletMethods", JSON.stringify(result))
    setWalletMethods(result);
  };
  






import React, { useRef, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import "./../ThankyouPage.scss";
import useFindHeight from '../../../../Hooks/useFindHeight';
import { t } from 'i18next';

export function OrderSummary(props) {
 
    const [observedDiv, elementHeight] = useFindHeight();
    

    return (
       
        <div className="details-block">
            <div className="item-container orderSummary-container">
              <div  className="item-wrapper">
                    <h4>{t("thankyoupage.ordersummary")}</h4>
                    {props?.items?.map((item, index) => (
                        <> <div className="two-columns-row">
                            <div className="module-column">
                                <div className="module-block">
                                    <h3 className="module-subheading">{item.displayname ? item.displayname : item.shortdescription}  </h3>
                                    <span className="module-quantity-block">  x{item.quantity}</span>
                                </div>

                            </div>
                            <div className="module-price">
                                {props?.currencysymbol}{item?.price?.toFixed(2)}
                            </div>
                        </div>
                            <div className="border-seperator"> </div>
                        </>
                    ))} 
                      
            </div>
                {props?.isOrderAnUpgrade ?
                 
                    <div className="item-wrapper">

                        <div className="div-wrapper">
                            <p>{t("thankyoupage.ordersummarysubtotal")}</p>
                            <p>{props?.currencysymbol}{props?.net !== "" ? props?.net?.toFixed(2) : props?.net}</p>
                        </div>

                        <div className="div-wrapper">
                            <p>{t("thankyoupage.previoussubscriptiondiscount")}</p>
                            <p>{t("upgrade.negativesign")}{props?.currencysymbol}{props?.prevSubsDiscount !== "" ? props?.prevSubsDiscount?.toFixed(2) : props?.prevSubsDiscount}</p>
                        </div>   
                       
                        {props.delivery &&
                            <div className="div-wrapper">
                                <p>{t("thankyoupage.ordersummarydelivery")}</p>
                                <p>{props?.currencysymbol}{props?.delivery !== "" ? props?.delivery?.toFixed(2) : props?.delivery}</p>
                            </div>
                        }

                        <div className="div-wrapper">
                            <p>{t("thankyoupage.ordersummarytax")}</p>
                            <p>{props?.currencysymbol}{props?.tax !== "" ? props?.tax?.toFixed(2) : props?.tax }</p>
                        </div>   

                        <div className="border-seperator"></div>
                        <div className="div-wrapper">
                            <p><strong>{t("thankyoupage.ordersummarytotal")}</strong></p>
                            <p>{props?.currencysymbol}{props?.amountToPay !== "" ? props?.amountToPay?.toFixed(2) : props?.amountToPay}</p>
                        </div>
                    </div> 

                    :

                      <div className="item-wrapper">

                        <div className="div-wrapper">
                            <p>{t("thankyoupage.ordersummarysubtotal")}</p>
                            <p>{props?.currencysymbol}{props?.net !== "" ? props?.net?.toFixed(2) : props?.net}</p>
                        </div>
                        
                        <div className="div-wrapper">
                            <p>{t("thankyoupage.ordersummarytax")}</p>
                            <p>{props?.currencysymbol}{props?.tax !== "" ? props?.tax?.toFixed(2) : props?.tax }</p>
                        </div>

                        {props.delivery &&
                            <div className="div-wrapper">
                                <p>{t("thankyoupage.ordersummarydelivery")}</p>
                                <p>{props?.currencysymbol}{props?.delivery !== "" ? props?.delivery?.toFixed(2) : props?.delivery}</p>
                            </div>
                        }

                        <div className="border-seperator"></div>
                        <div className="div-wrapper">
                            <p><strong>{t("thankyoupage.ordersummarytotal")}</strong></p>
                            <p>{props?.currencysymbol}{props?.total !== "" ? props?.total?.toFixed(2) : props?.total}</p>
                        </div>
                    </div>
                }
            </div>
        </div>


    )
}
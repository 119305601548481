import { useRef, useEffect, useState, useMemo } from "react";
import SurveyComponent from "./SurveyForm";
import InputWrapperRefacture from "../Inputs/InputWrapperRefacture"
import ErrorBox from '../ErrorBox/ErrorBox';
import { useTranslation } from 'react-i18next';
import '../../../i18n'
const CustomForms = (props) => {
    const surveyRef = useRef(null);
    const [IsBlank, setIsBlank] = useState(true);
    const { t, i18n } = useTranslation();
    const handleSubmit = (event) => {
        event.preventDefault();
        if (surveyRef.current) {
            const isValid = surveyRef.current.validateSurvey();
            if (isValid) {
                const surveyData = surveyRef.current.getSurveyData();
                console.log('Survey Data:', surveyData);
            } else {
                console.log('Survey validation failed.');
            }
        }
    };

    return (
        <div className="container">
            <div className="item-container" aria-live="polite">
                <h1>SurveyJS Example</h1>
            </div>
             <div className="Form">
                <form onSubmit={handleSubmit}>

                    <div className="item-container">
                        <div className="item-wrapper">
                            <InputWrapperRefacture
                                label={t('directdebitform.accountholder')}
                                inputSize="medium"
                                id="accountholder"
                                inputFieldType="text"
                                specs="text_input"
                                name="accountholder"
                                type="text"
                                invalidClass={((IsBlank)) && "Invalid-input"}
                                aria-invalid={IsBlank.accountholder}
                                aria-describedby="accountholder-blank-error"
                             
                            />

                            {IsBlank  && <span role="alert" id='accountholder-blank-error'> <ErrorBox>{t('directdebitform.blankaccountholder')}</ErrorBox></span>}
                        </div>
                    </div> 
                        <div className="item-container">
                            <div className="item-wrapper">
                                <SurveyComponent ref={surveyRef} />
                                <button >Submit Survey</button>
                            </div>
                        </div>
                 </form>
             </div>
        </div>
    );
};

export default CustomForms;



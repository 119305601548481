import React, { Component, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import '../../../i18n'
import { PaymentTypeLogo } from './PaymentTypeLogo'
import InputWrapperRefacture from '../../UI/Inputs/InputWrapperRefacture';
import useResize from '../../../Hooks/useResize';

export function PaymentSelection(props) {
    const { t, i18n } = useTranslation();
    const [paymentTypeValue, setPaymentType] = useState("");
    const {labelName, paymentProvider, walletMethods, paymentTypes, enableWallets} = props;
    let windowSize = useResize();

    //focus on payment method on on-click of the whole bar
    const handleOnClick=(e,id)=>{
      const paymentMethod = document.querySelector(`#radio${t(id)}`)
        props.setErrorStates(errorStates=>({...errorStates, noPaymentSelected: false}))
        if(paymentMethod){
            paymentMethod.focus();
            paymentMethod.click();
        }
    }
    

   const displayData = () => {

        return (

            <div role="radiogroup" aria-labelledby="legendpaymentmethodgroup" id="paymentmethodgroup" className="item-container">
                <div className="item-wrapper">
                    <label className="label">{t('paymenttype.heading')}</label>
                </div>

                {props.paymentTypes?.map((paymentType) => (
                    
                    <div onClick={(e)=>handleOnClick(e,paymentType.paymenttypeid)} className={`${(paymentType.subpaymenttypes.length > 3 && windowSize < 768) ? "payment-wrapper-mobile" : "payment-wrapper"}`}>
                        <div  id={t(paymentType.paymenttypenamelanguagekey)} className={`div-wrapper border-wrapper ${(paymentTypeValue === paymentType.paymenttypeid) ? "filled" : ""}`}>
                          
                            <InputWrapperRefacture
                                labelText={(paymentProvider==="stripe" && (paymentType.name === "Creditdebitcard" || paymentType.name === "Continuouscreditdebitcard" ) && (walletMethods.applePayAvailable || walletMethods.googlePayAvailable)) ? t("paymenttype.stripemethods") : t(paymentType.paymenttypenamelanguagekey)}
                                inputSize="full-width"
                                inputFieldType="radio"
                                id={"radio"+paymentType.paymenttypeid}
                                name="payment_type"
                                role="radio"
                                tabIndex="0"
                                value={paymentType.paymenttypeid}
                                onChange={(e) => { setPaymentType(paymentType.paymenttypeid); props.onChangePaymentSelection(paymentType) }}
                            />
                                <div className="payment-icon-div">
                                    <PaymentTypeLogo paymentType={paymentType} walletMethods={walletMethods} awaitWalletCheck={(paymentProvider==="stripe" && (paymentType.name === "Creditdebitcard" || paymentType.name === "Continuouscreditdebitcard") && enableWallets)}/>
                                </div>
                         </div>
                    </div>
                ))}
            </div>


        )
    }

    let content = displayData();

    return (<div>
        {content} 
        </div>
    );

}
import React, { useState, useEffect } from 'react';
import ReactHtmlParser from 'html-react-parser';
import { useParams } from 'react-router-dom'; 
import '../../../i18n'
import "./ThankyouPage.scss";
import { APIGet } from '../../API/APIRequest'
 import { BillingDetails } from './OrderDetails/BillingDetails';
import { DeliveryDetails } from './OrderDetails/DeliveryDetails';
 
import { OrderSummary } from './OrderDetails/OrderSummary';
import { GenericErrorBox } from '../../UI/ErrorBox/GenericErrorBox';
import { CustomerDetails } from './OrderDetails/CustomerDetails';
import { UserSignin } from './MessageBox/UserSignin';
import { UserGuest } from './MessageBox/UserGuest';
import { GetADToken, RemoveADToken } from '../../Utilities/TokenManager';
import usePageTitle from '../../../Hooks/usePageTitle';
import useDateLocalisation from '../../../Hooks/useDateLocalisation';
import { gtmEventTriggers } from '../../Utilities/GtmFunctions';
import { t } from 'i18next';
import { format } from 'react-string-format';
import { LogWebActivity } from '../../Webactivity/LogWebActivity';

export function ThankyouPage(props) {

   
    const {metaData} = props;
    const { orderid } = useParams();
    const [errors, setErrors] = useState([]);
    const [order, setOrder] = useState();
    const [actionName, setActionName] = useState("");
    const [customThankyouMessage, setCustomThankyouMessage] = useState({"show": "", "text":""});
    const [customButtonText, setCustomButtonText] = useState({ "show": "", "text": "" });
    const [isFreeTrial, setIsFreeTrial] = useState(false);
    const [upgradeDetails, setUpgradeDetails] =useState({})
    

    const fid=  sessionStorage.getItem("fid");
    const isOrderAnUpgrade=sessionStorage.getItem("isOrderAnUpgrade");
    
    const userToken = GetADToken();
  
    usePageTitle(`Thank you for your order -  ${props.brandingElement?.id}`);
 
    const LoadSummary =  (orderid, data) => { 
        APIGet({
            "controller": "order",
            "action": "summary",
            "environment" : null,
            "identifier": orderid,
            "data" : data,
            "headers": [{
                "key": "UserToken",
                "value": userToken.token
            }]
        }).then((response) => { 
            sessionStorage.setItem("postcompleteurl-default", response.data.postcompleteurl);
            setOrder(response.data.order); 
            checkForThankyouMessage(response.data.order);
            checkForButtontext(response.data.order);
            if(isOrderAnUpgrade){
              getUpgradeDetails(response.data.order);
            }
       
        }).catch((error) => {
            var unexpected = [];
            unexpected.push("error.unexpected");
            setErrors(unexpected);
        })
    }
    const formattedDate = useDateLocalisation(order?.orderdate);

    const getUpgradeDetails = (order) => {
        let prevSubsDiscount = 0;
        order?.paymentsbytype?.map((item) => {
            if (item?.name === "Order Payment Transfer") {
                prevSubsDiscount = item?.credit;
                setUpgradeDetails(upgradeDetails => ({ ...upgradeDetails, prevSubsDiscount: prevSubsDiscount }));
            }
        });
        let amountToPay = order?.total - prevSubsDiscount;
        setUpgradeDetails(upgradeDetails => ({ ...upgradeDetails, amountToPay: amountToPay }));
    }

    //Removing guest token on completing order
    useEffect(() => {

        LoadSummary(orderid);
       
        sessionStorage.setItem("orderId", orderid)
        sessionStorage.removeItem("stripeData");  //deletion once the process starts
          
        // if (userToken?.isGuest) {
        //     RemoveADToken();
        // }

    }, [])
     
    useEffect(()=>{
        
        //to be fired once order is made and flow is completed
        if(metaData?.basketmetadata){
            gtmEventTriggers("purchase", orderid);
         }

        //web-activity: not to fire the log if already fired, only to be fired once
        let checkoutCompleted = sessionStorage.getItem("CheckoutCompleted");
        if (checkoutCompleted === null) {
            let adtoken = sessionStorage.getItem("adtoken");
            
            //userToken is needed
            if (adtoken) {
                setActionName("Checkout completed")
                sessionStorage.setItem("CheckoutCompleted", true)}
        }

     }, [metaData?.basketmetadata]);

    useEffect(()=>{

      props.setFid(fid);

    }, [fid])

    //set state to check if is untokenised free trial
    useEffect(() => {
        if (order?.payment.NextPayment === null && order.ordertypename === "Free trial") {
            setIsFreeTrial(true)
        }
    }, [order])

    const checkForThankyouMessage=(order)=>{
       let count=0;
        if(order?.lines){
            order.lines.forEach((variant, id)=>{
               if (variant?.thankyoutext){
                     setCustomThankyouMessage(customThankyouMessage=>({...customThankyouMessage, text:variant?.thankyoutext }))
                     count=count+1;
                  }
               })
           }
        if(count===1){
            setCustomThankyouMessage(customThankyouMessage=>({...customThankyouMessage, show: "true" })) 
        }
        else{
            setCustomThankyouMessage(customThankyouMessage=>({...customThankyouMessage, show: "false" })) 
    }}
     
    const checkForButtontext=(order)=>{
            let count=0;
             if(order?.lines){
                 order.lines.forEach((variant, id)=>{
                    if (variant?.thankyoubuttontext){
                          setCustomButtonText(customButtonText=>({...customButtonText, text:variant?.thankyoubuttontext }))
                          count=count+1;
                       }
                    })
                }
             if(count===1){
                setCustomButtonText(customButtonText=>({...customButtonText, show: "true"}))
             }else{
                setCustomButtonText(customButtonText=>({...customButtonText, show: "false"}))
             }}    

    let orderConfirmationText = format(t('thankyoupage.orderconfirmationtext'), order?.purchaser?.email)

    if (order?.payment?.directdebit && upgradeDetails?.amountToPay) {
        order.payment.NextPayment.amount = upgradeDetails.amountToPay;
    }
    return (
        <>
        <div className="thankyou-page">
            <div className="container-wrapper">
                <div className="container">
                    <GenericErrorBox errors={errors} /> 
                    <div className="item-container">
                        <div className="item-wrapper">
                            <h2>{t('thankyoupage.thankyoutext')}</h2>
                            <p>{order?.purchaser?.email && ReactHtmlParser(orderConfirmationText || "")} </p>
                        </div>
                    </div>
                    {/* {isloggedIn !== null ? < UserSignin /> : <UserGuest/>} */}
                    <UserSignin customThankyouMessage={customThankyouMessage} customButtonText={customButtonText}/>
                    <div className="item-container">
                        <div className="item-wrapper">
                            <div className="border-seperator"></div>
                            <div className="div-wrapper">
                                <h3>{t('thankyoupage.orderdetailslabel')}</h3>
                                <div className="div-wrapper refId">
                                    <p> <strong>{t('thankyoupage.reflabel')}</strong></p>
                                    <p>{order?.orderCode }</p> 
                                </div>
                            </div>
                            <p> {t('thankyoupage.purchasedlabel')} {formattedDate}</p>
                        </div>
                    </div>
         
                    <CustomerDetails
                        purchaserName={order?.purchaser.name}
                        purchaserEmail={order?.purchaser.email}
                        purchaserAddress={order?.purchaser.Address}
                    />

                 <DeliveryDetails
                        purchaser={order?.purchaser}
                        recipients={order?.recipients}
                        />
                    
                        
                        {/*if not tokenised FT then show the Billing Details  */} 

                        {!isFreeTrial &&
                                <BillingDetails
                                    cardType={order?.payment?.creditdebitcard?.cardtype}
                                    cardNumber={order?.payment?.creditdebitcard?.cardnumber}
                                    directDebit={order?.payment?.directdebit}
                                    billingAddress={order?.purchaser.Address}
                                    recipientAddress={order?.recipients}
                                    nextPayment={order?.payment?.NextPayment}
                                    currency={order?.currencysymbol}
                                    paymentType={order?.paymenttypeId}

                                /> 
                         
                   }

                    <OrderSummary
                        net={order?.net}
                        tax={order?.tax}
                        delivery={order?.deliveryCost}
                        total={order?.total}
                        prevSubsDiscount={upgradeDetails?.prevSubsDiscount}
                        amountToPay={upgradeDetails?.amountToPay}
                        currencysymbol={order?.currencysymbol}
                        items={order?.lines}
                        isOrderAnUpgrade={isOrderAnUpgrade}
                         />

                     <div className="item-container">
                         <div className="border-seperator"></div>
                     </div>
                     
                    <UserSignin customThankyouMessage={customThankyouMessage} customButtonText={customButtonText}/>

                </div>

            </div>
        </div>

         <LogWebActivity actionName={actionName}/>

       
</>

    )
}



